import React, { Component } from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import axios from 'axios';
import Countries from '../Countries/Countries';
import Input from '../../components/UI/Input/Input';
import Dropdown from '../../components/UI/Dropdown/Dropdown';
import classes from './Homepage.module.css';
import { ThemeContext } from '../../context/ThemeContext';
import Radium from 'radium';

class Homepage extends Component {
  state = {
    countries: [],
    regionsDefaultValue: [
      {
        value: 'filterByRegion',
        label: 'Filter By Region',
      },
    ],
    regions: [
      {
        value: 'africa',
        label: 'Africa',
      },
      {
        value: 'america',
        label: 'America',
      },
      {
        value: 'asia',
        label: 'Asia',
      },
      {
        value: 'europe',
        label: 'Europe',
      },
      {
        value: 'oceania',
        label: 'Oceania',
      },
    ],
    option: '',
    selectedOption: '',
    selected: false,
    activeSearchOption: 0,
    filteredSearchOptions: [],
    showSearchOptions: false,
    searchValue: '',
    searched: false,
    searchOptionClicked: false,
    searchOptionEnter: false,
  };

  componentDidMount() {
    const { option } = this.state;

    this.searchCountries(option);
    //console.log('[Homepage.js] did Mount');
  }

  searchCountries = (option = 'all') => {
    axios
      .get(`/${option}`)
      .then(response => {
        let countries = response.data;
        this.setState({ countries: countries });
      })
      .catch(error => {
        console.log(error);
      });
  };

  selectChangeHandler = e => {
    const value = e.value;

    this.setState(
      {
        regionsDefaultValue: [
          {
            value: value,
            label: value[0].toUpperCase() + value.slice(1),
          },
        ],
        option: 'region/' + (value === 'america' ? 'americas' : value),
        selectedOption: value[0].toUpperCase() + value.slice(1),
        selected: true,
        searched: false,
        searchValue: '',
      },
      () => {
        if (this.state.option.startsWith('region/')) {
          this.searchCountries(this.state.option);
        }
      }
    );
  };

  inputChangeHandler = e => {
    const searchValue = e.currentTarget.value;
    const { countries } = this.state;

    const filteredSearchOptions = countries.filter(
      option =>
        option.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
        option.altSpellings.some(
          el => el.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
        )
    );

    this.setState(
      {
        option: `name/${searchValue.toLowerCase()}`,
        activeSearchOption: 0,
        filteredSearchOptions,
        showSearchOptions: true,
        searchValue,
        searched: true,
        searchOptionClicked: false,
      },
      () => {
        if (searchValue.length > 0) {
          this.searchCountries(this.state.option);
        } else if (searchValue.length == 0) {
          this.searchCountries('all');
        }
      }
    );
  };

  inputClickHandler = e => {
    let value = e.currentTarget.innerText;

    this.setState(
      {
        option: `name/${value.toLowerCase()}?fullText=true`,
        activeSearchOption: 0,
        filteredSearchOptions: [],
        showSearchOptions: false,
        searchValue: value,
        searched: true,
        searchOptionClicked: true,
      },
      () => {
        if (this.state.option && this.state.option.length > 1) {
          this.searchCountries(this.state.option);
        }
      }
    );
  };

  keyDownPressHandler = e => {
    const { activeSearchOption, filteredSearchOptions } = this.state;

    if (e.keyCode === 13) {
      let value = filteredSearchOptions[activeSearchOption].name;
      this.setState(
        {
          option: `name/${value.toLowerCase()}?fullText=true`,
          activeSearchOption: 0,
          showSearchOptions: false,
          searchValue: value,
          searched: true,
          searchOptionEnter: true,
        },
        () => {
          if (this.state.option && this.state.option.length > 1) {
            this.searchCountries(this.state.option);
          }
        }
      );
    } else if (e.keyCode === 38) {
      if (activeSearchOption === 0) {
        return;
      }
      this.setState({ activeSearchOption: activeSearchOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeSearchOption === filteredSearchOptions.length - 1) {
        return;
      }
      this.setState({ activeSearchOption: activeSearchOption + 1 });
    }
  };

  inputFocusHandler = () => {
    if (this.state.selected || this.state.searched) {
      this.setState(
        {
          regionsDefaultValue: [
            {
              value: 'filterByRegion',
              label: 'Filter By Region',
            },
          ],
          option: 'all',
          selectedOption: '',
          selected: false,
          searchValue: '',
        },
        () => {
          this.searchCountries(this.state.option);
        }
      );
    }
  };

  static contextType = ThemeContext;

  render() {
    const {
      activeSearchOption,
      filteredSearchOptions,
      showSearchOptions,
      searchValue,
    } = this.state;

    const { theme } = this.context;

    const styleList = {
      color: theme.text,
      backgroundColor: theme.elements,
      boxShadow: `0 1px 10px ${theme.shadow}`,
    };

    const styleOption = {
      ':hover': {
        backgroundColor: theme.hover,
      },
    };

    const styleOptionActive = {
      backgroundColor: theme.optionActive,
    };

    let searchOptionList;
    if (showSearchOptions && searchValue) {
      if (filteredSearchOptions.length) {
        searchOptionList = (
          <ul style={styleList} className={classes.SearchOptions}>
            {filteredSearchOptions.map((optionName, i) => {
              let optionClassName;
              if (i === activeSearchOption) {
                optionClassName = 'OptionActive';
              }
              return (
                <li
                  style={
                    optionClassName === 'OptionActive'
                      ? styleOptionActive
                      : styleOption
                  }
                  className={optionClassName}
                  key={optionName.name}
                  onClick={this.inputClickHandler}
                >
                  {optionName.name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        searchOptionList = (
          <ul style={styleList} className={classes.SearchOptions}>
            <li>
              <em>No Countries Found!</em>
            </li>
          </ul>
        );
      }
    }

    return (
      <Auxiliary>
        <div className={classes.SearchFilter}>
          <div className={classes.Input}>
            <Input
              changed={e => this.inputChangeHandler(e)}
              keyDownPressed={e => this.keyDownPressHandler(e)}
              focused={e => this.inputFocusHandler(e)}
              searchValue={this.state.searchValue}
            />
            {searchOptionList}
          </div>
          <Dropdown
            region={this.state.regions}
            value={this.state.regionsDefaultValue}
            changed={e => this.selectChangeHandler(e)}
          />
        </div>
        <Countries countries={this.state.countries} error={this.state.error} />
      </Auxiliary>
    );
  }
}

export default Radium(Homepage);
