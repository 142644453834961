import React, { useContext } from 'react';
import classes from './Country.module.css';
import { ThemeContext } from '../../context/ThemeContext';

const Country = props => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{
        color: theme.text,
        backgroundColor: theme.elements,
        boxShadow: `0 3px 15px ${theme.shadow}`,
      }}
      className={classes.Country}
      onClick={props.clicked}
    >
      <div className={classes.Image}>
        <img src={props.img} alt="Country Flag" />
      </div>

      <div className={classes.Info}>
        <h3>{props.name}</h3>
        <p>
          <strong>Population:</strong>{' '}
          {Number(props.population).toLocaleString('en-US')}
        </p>
        <p>
          <strong>Region:</strong> {props.region ? props.region : ' /'}
        </p>
        <p>
          <strong>Capital:</strong> {props.capital ? props.capital : ' /'}
        </p>
      </div>
    </div>
  );
};

export default Country;
