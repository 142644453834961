import React, { useContext } from 'react';
import Auxiliary from '../../hoc/Auxiliary';
import classes from './Layout.module.css';
import Navigation from '../Navigation/Navigation';
import { ThemeContext } from '../../context/ThemeContext';

const Layout = props => {
  const { theme } = useContext(ThemeContext);
  return (
    <Auxiliary>
      <Navigation />
      <main
        style={{ color: theme.text, backgroundColor: theme.body }}
        className={classes.Content}
      >
        {props.children}
      </main>
    </Auxiliary>
  );
};

export default Layout;
