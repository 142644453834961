import React from 'react';

export const themes = {
  light: {
    text: 'hsl(200, 15%, 8%)',
    body: 'hsl(0, 0%, 98%)',
    elements: 'hsl(0, 0%, 100%)',
    input: 'hsl(0, 0%, 52%)',
    icon: 'faMoon',
    shadow: 'hsl(0, 0%, 80%)',
    hover: 'hsl(0, 0%, 85%)',
    optionActive: 'hsl(198, 80%, 80%)',
  },
  dark: {
    text: 'hsl(0, 0%, 100%)',
    body: 'hsl(207, 26%, 17%)',
    elements: 'hsl(209, 23%, 22%)',
    input: 'hsl(0, 0%, 100%)',
    icon: 'faMoonSolid',
    shadow: 'hsl(207, 26%, 15%)',
    hover: 'hsl(207, 26%, 17%)',
    optionActive: 'hsl(198, 81%, 58%)',
  },
};

export const ThemeContext = React.createContext();
