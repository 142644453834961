import React, { useState } from 'react';
import Layout from './components/Layout/Layout';
import Homepage from './containers/Homepage/Homepage';
import CountryDetails from './containers/CountryDetails/CountryDetails';
import { Route, Switch } from 'react-router-dom';
import { ThemeContext, themes } from './context/ThemeContext';

function App() {
  const [theme, setTheme] = useState(themes.light);

  return (
    <div>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <Layout>
          <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/country/:name" component={CountryDetails} />
          </Switch>
        </Layout>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
