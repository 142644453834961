import React, { useContext } from 'react';
import classes from './Navigation.module.css';
import Logo from '../Logo/Logo';
import ThemeSwitcher from '../UI/ThemeSwitcher/ThemeSwitcher';
import { ThemeContext } from '../../context/ThemeContext';

const Navigation = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <header
      style={{
        color: theme.text,
        backgroundColor: theme.elements,
        boxShadow: `0 1px 5px ${theme.shadow}`,
      }}
      className={classes.Navigation}
    >
      <Logo />
      <ThemeSwitcher />
    </header>
  );
};

export default Navigation;
