import React, { Component } from 'react';
import Country from '../../components/Country/Country';
import { Link, withRouter } from 'react-router-dom';
import classes from './Countries.module.css';

class Countries extends Component {
  createCountry(element, id) {
    return (
      <Link
        to={'/country/' + element.name}
        key={id}
        style={{ textDecoration: 'none' }}
      >
        <Country
          img={element.flag}
          name={element.name}
          population={element.population}
          region={element.region}
          capital={element.capital}
        />
      </Link>
    );
  }

  // componentDidMount() {
  //   console.log('[COUNTRIES] did mount');
  //   console.log(this.props);
  // }

  // componentDidUpdate() {
  //   console.log('[COUNTRIES] did update');
  //   console.log(this.props);
  // }

  render() {
    let allCountries = this.props.countries;
    let countries = null;

    if (allCountries) {
      countries = allCountries.map(el => {
        let id = +el.numericCode;
        return this.createCountry(el, id);
      });
    }

    return (
      <div>
        <section className={classes.Countries}>{countries}</section>
      </div>
    );
  }
}

export default withRouter(Countries);
