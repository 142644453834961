import React, { useContext } from 'react';
import classes from './ThemeSwitcher.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon } from '@fortawesome/free-regular-svg-icons';
import { faMoon as faMoonSolid } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext, themes } from '../../../context/ThemeContext';

const ThemeSwitcher = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const toggleTheme = () => {
    setTheme(theme === themes.light ? themes.dark : themes.light);
  };

  return (
    <button
      style={{
        color: theme.text,
        backgroundColor: theme.elements,
      }}
      className={classes.Switcher}
      onClick={toggleTheme}
    >
      <FontAwesomeIcon
        icon={theme.icon === 'faMoon' ? faMoon : faMoonSolid}
        className={classes.icon}
      />
      Dark Mode
    </button>
  );
};

export default ThemeSwitcher;
