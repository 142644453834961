import React, { useContext } from 'react';
import Select from 'react-select';
import classes from './Dropdown.module.css';
import { ThemeContext } from '../../../context/ThemeContext';

const Dropdown = props => {
  const { theme } = useContext(ThemeContext);

  const customStyles = {
    container: provided => ({
      ...provided,
      backgroundColor: theme.body,
      borderRadius: '5px',
      width: '200px',
    }),
    control: provided => ({
      ...provided,
      color: theme.text,
      backgroundColor: theme.elements,
      border: 'none',
      width: '200px',
      boxShadow: `0 1px 10px ${theme.shadow}`,
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: theme.text,
      paddingRight: '15px',
      ':hover': {
        color: theme.text,
        cursor: 'pointer',
      },
    }),
    input: provided => ({
      ...provided,
      color: theme.text,
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none',
    }),
    menu: provided => ({
      ...provided,
      color: theme.text,
      backgroundColor: theme.elements,
      boxShadow: `0 1px 10px ${theme.shadow}`,
    }),
    option: (provided, state) => ({
      ...provided,
      color: theme.text,
      backgroundColor: state.isFocused ? theme.hover : theme.elements,
      fontWeight: 600,
      padding: '5px 20px',
      ':hover': {
        backgroundColor: theme.hover,
      },
    }),
    singleValue: provided => ({
      ...provided,
      color: theme.text,
    }),
    valueContainer: provided => ({
      ...provided,
      color: theme.text,

      fontWeight: 600,
      padding: '8px 20px',
      border: 'transparent',
    }),
  };

  return (
    <div className={classes.Dropdown}>
      <Select
        styles={customStyles}
        options={props.region}
        value={props.value}
        onChange={props.changed}
      />
    </div>
  );
};

export default Dropdown;
