import React, { useContext } from 'react';
import classes from './Button.module.css';
import { ThemeContext } from '../../../context/ThemeContext';

const Button = props => {
  const { theme } = useContext(ThemeContext);

  return (
    <button
      style={{
        color: theme.text,
        backgroundColor: theme.elements,
        boxShadow: `0 0 3px 2px ${theme.shadow}`,
        border: `1px solid ${theme.shadow}`,
      }}
      className={[classes.Button, classes[props.btnType]].join(' ')}
      onClick={props.clicked}
    >
      {props.children}
    </button>
  );
};

export default Button;
