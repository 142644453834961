import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import classes from './Input.module.css';
import { ThemeContext } from '../../../context/ThemeContext';
import Radium, { Style } from 'radium';

const Input = props => {
  const { theme } = useContext(ThemeContext);

  return (
    <div
      style={{
        color: theme.input,
        backgroundColor: theme.elements,
        boxShadow: `0 1px 10px ${theme.shadow}`,
      }}
      className={classes.Search}
    >
      <Style
        rules={{
          input: {
            color: theme.input,
            backgroundColor: theme.elements,
          },
          '::placeholder': {
            color: theme.input,
          },
        }}
      />

      <FontAwesomeIcon icon={faSearch} className={classes.Icon} />
      <input
        type="text"
        placeholder="Search for a country..."
        onChange={props.changed}
        onKeyDown={props.keyDownPressed}
        onFocus={props.focused}
        value={props.searchValue}
      />
    </div>
  );
};

export default Radium(Input);
