import React, { Component } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/UI/Button/Button';
import classes from './CountryDetails.module.css';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../context/ThemeContext';

class CountryDetails extends Component {
  state = {
    allCountries: [],
    loadedCountry: null,
  };

  componentDidMount() {
    // console.log('[CountryDetails.js] did MOUNT');
    // console.log(this.props);

    this.loadCountry();
  }

  loadCountry() {
    axios.get('/all').then(response => {
      console.log(response.data);
      let countries = response.data;
      let country = response.data.filter(
        el => el.name === this.props.match.params.name
      );
      console.log(country);
      this.setState({ allCountries: countries, loadedCountry: country });
    });
  }

  componentDidUpdate() {
    // console.log('[CountryDetails.js] did update');
    // console.log(this.props);

    if (this.props.match.params.name) {
      if (
        !this.state.loadedCountry ||
        (this.state.loadedCountry &&
          this.state.loadedCountry[0].name !== this.props.match.params.name)
      ) {
        this.loadCountry();
      }
    }
  }

  goToPreviousPage = () => {
    this.props.history.goBack();
  };

  static contextType = ThemeContext;

  render() {
    let country = (
      <p
        style={{
          textAlign: 'center',
          fontSize: '24px',
          marginTop: '100px',
        }}
      >
        Loading Country ...
      </p>
    );

    if (this.state.loadedCountry) {
      const loadedCountry = this.state.loadedCountry[0];
      const allCountries = this.state.allCountries;

      let borderCountries = [];
      allCountries.filter(country => {
        for (let i = 0; i < loadedCountry.borders.length; i++) {
          if (country.alpha3Code === loadedCountry.borders[i]) {
            borderCountries.push(country.name);
          }
        }
        return borderCountries;
      });

      console.log(borderCountries);

      const { theme } = this.context;

      country = (
        <div style={{ color: theme.text }} className={classes.FullCountry}>
          <Button btnType="Back" clicked={this.goToPreviousPage}>
            <FontAwesomeIcon
              style={{ color: theme.text }}
              icon={faLongArrowAltLeft}
              className={classes.Icon}
            />
            Back
          </Button>
          <div className={classes.CountryDetails}>
            <img src={loadedCountry.flag} alt="Flag" />
            <div className={classes.CountryInfo}>
              <h2>{loadedCountry.name}</h2>
              <div className={classes.Info}>
                <div className={classes.column}>
                  <p>
                    <strong>Native Name:</strong> {loadedCountry.nativeName}
                  </p>
                  <p>
                    <strong>Population:</strong>{' '}
                    {Number(loadedCountry.population).toLocaleString('en-US')}
                  </p>
                  <p>
                    <strong>Region:</strong> {loadedCountry.region}
                  </p>
                  <p>
                    <strong>Sub Region:</strong>{' '}
                    {loadedCountry.subregion ? loadedCountry.subregion : ' /'}
                  </p>
                  <p>
                    <strong>Capital:</strong>{' '}
                    {loadedCountry.capital ? loadedCountry.capital : ' /'}
                  </p>
                </div>
                <div className={classes.column}>
                  <p>
                    <strong>Top Level Domain:</strong>{' '}
                    {loadedCountry.topLevelDomain}
                  </p>
                  <p>
                    <strong>Currencies:</strong>{' '}
                    {loadedCountry.currencies.map(el => el.name).join(', ')}
                  </p>
                  <p>
                    <strong>Languages:</strong>{' '}
                    {loadedCountry.languages.map(el => el.name).join(', ')}
                  </p>
                </div>
              </div>
              <div className={classes.BorderCountries}>
                <h3>Border Countries:</h3>
                <div>
                  {loadedCountry.borders.length > 0 ? (
                    borderCountries.map((el, i) => (
                      <Link key={i} to={'/country/' + el}>
                        <Button btnType="BorderCountries">{el}</Button>
                      </Link>
                    ))
                  ) : (
                    <span style={{ display: 'inline-block', marginTop: '4px' }}>
                      {' '}
                      /
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return country;
  }
}

export default CountryDetails;
